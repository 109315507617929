import React from 'react'
import { graphql } from 'gatsby'
import { LandingPageContextProps } from 'templates/layouts/LandingPageLayout'
import BlogLandingLayout from './layouts/BlogLandingLayout'

interface Props {
  data: Queries.BlogLandingQuery
  pageContext: LandingPageContextProps
}

const BlogLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage
  const posts = data.allDatoCmsBlogPost.edges

  return (
    <BlogLandingLayout
      pageContext={pageContext}
      landing={landing}
      posts={posts}
      highlightPost={data.highlightPost.edges[0].node}
    />
  )
}

export const pageQuery = graphql`
  query BlogLanding($skip: Int!, $perPage: Int!, $originalId: String!) {
    datoCmsLandingPage(originalId: { eq: $originalId }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    highlightPost: allDatoCmsBlogPost(
      limit: 1
      sort: { fields: [isSeoPost, meta___createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          heroImageMainLarge: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: { ar: "4:3", fit: "crop", crop: "focalpoint" }
            )
          }
          heroImageHoverLarge: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: {
                ar: "4:3"
                fit: "crop"
                crop: "focalpoint"
                sat: -100
              }
            )
          }
          heroImageMainSmall: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: { ar: "16:8", fit: "crop", crop: "focalpoint" }
            )
          }
          heroImageHoverSmall: heroImage {
            gatsbyImageData(
              width: 1120
              layout: CONSTRAINED
              imgixParams: {
                ar: "16:8"
                fit: "crop"
                crop: "focalpoint"
                sat: -100
              }
            )
          }
        }
      }
    }
    allDatoCmsBlogPost(
      limit: $perPage
      skip: $skip
      sort: { fields: [isSeoPost, meta___createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          ...cardBlogPost
        }
      }
    }
  }
`

export default BlogLanding
